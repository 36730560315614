import React from "react";

export const DiabetesIcon = () => (
  <svg width="161" height="117" viewBox="0 0 161 117">
    <g id="Diabetes 1" clipPath="url(#clip0_1179_2979)">
      <path
        id="Vector"
        d="M89.4706 74.2913C89.4706 74.2913 89.4873 74.4088 89.504 74.4423C94.0855 76.154 97.3627 80.5675 97.3627 85.753C97.3627 88.7065 96.3093 91.3916 94.5537 93.506C99.2522 95.1506 102.63 99.6312 102.63 104.917C102.63 107.065 102.061 109.079 101.091 110.824H111.375C125.587 110.824 131.523 107.367 135.101 104.364L136.038 103.491C141.204 99.2285 145.502 97.5336 150.969 97.5336H155.701C157.122 97.0805 157.992 96.8287 157.992 96.8287C157.992 96.8287 138.061 80.4501 115.103 85.1657C99.921 88.287 97.6804 64.6252 91.778 60.9836C92.3633 62.4604 92.6977 64.0714 92.6977 65.7496C92.6977 69.0219 91.4603 72.009 89.4539 74.2913H89.4706Z"
        fill="#EBB6DB"
      />
      <path
        id="Vector_2"
        d="M28.5237 81.0375C22.404 81.9102 21.0663 75.0969 20.7821 72.5461C20.0798 73.7544 19.411 74.9794 18.8926 76.1709C18.8592 76.2548 18.8257 76.322 18.7756 76.4059C18.1402 77.5134 17.7891 78.772 17.7556 80.0642V80.3159C17.7556 84.6791 21.2837 88.2368 25.631 88.2368C29.4768 88.2368 32.6872 85.451 33.3727 81.7759C32.3528 81.1047 30.8312 80.7355 28.5237 81.0543V81.0375Z"
        fill="#EBB6DB"
      />
      <path
        id="Vector_3"
        d="M19.8794 57.7953C21.2672 57.7953 22.3875 56.6709 22.3875 55.2781C22.3875 53.8852 21.2672 52.7609 19.8794 52.7609H13.3917C9.04438 52.7609 5.51632 49.22 5.51632 44.8568C5.51632 40.4937 9.04438 36.9528 13.3917 36.9528L89.8888 36.9863L95.3063 40.6111C95.9082 76.4059 120.454 78.6882 120.705 78.7049C120.772 78.7049 120.839 78.7049 120.889 78.7049C122.176 78.7049 123.28 77.6981 123.38 76.3891C123.497 75.013 122.461 73.8048 121.09 73.6705C120.237 73.6034 100.306 71.5057 100.306 39.2351C100.306 38.396 99.8877 37.6073 99.1854 37.1374L92.5139 32.6735C92.2798 32.4386 91.9956 32.2372 91.6779 32.103L72.0478 18.9631C70.3256 17.8052 69.1719 16.0599 68.7873 14.0126C68.4027 11.982 68.8542 9.91793 70.0413 8.22301C72.215 5.11845 76.3952 4.09478 79.756 5.84005C94.8882 13.677 131.055 32.7742 140.168 40.6447C143.679 43.6821 147.759 45.2764 151.956 45.2764H157.975C159.363 45.2764 160.483 44.152 160.483 42.7592C160.483 41.3663 159.363 40.2419 157.975 40.2419H151.956C148.194 40.2419 145.234 38.3792 143.428 36.8185C134.249 28.8977 101.025 11.2101 82.0468 1.35941C76.4621 -1.52699 69.5397 0.167932 65.9281 5.33661C63.955 8.15588 63.2193 11.5625 63.8547 14.9524C64.4901 18.3422 66.3962 21.2454 69.2555 23.1585L82.3477 31.9184L13.375 31.8848C6.26875 31.8848 0.483398 37.6912 0.483398 44.8233C0.483398 51.9554 6.26875 57.7617 13.375 57.7617H19.8626L19.8794 57.7953Z"
        fill="black"
      />
      <path
        id="Vector_4"
        d="M157.992 97.5168H150.969C145.502 97.5168 141.204 99.2117 136.038 103.474L135.101 104.347C131.523 107.351 125.571 110.808 111.375 110.808H101.092C102.078 109.062 102.63 107.049 102.63 104.901C102.63 99.6313 99.2523 95.1506 94.5538 93.4893C96.3095 91.3916 97.3629 88.6898 97.3629 85.7363C97.3629 80.5508 94.0856 76.1373 89.5042 74.4256C89.4707 74.4256 89.4373 74.392 89.3871 74.3753C89.4206 74.3417 89.4373 74.3081 89.4707 74.2746C91.4772 71.9923 92.7145 69.0052 92.7145 65.7328C92.7145 64.0547 92.3801 62.4437 91.7949 60.9669C89.9055 56.1842 85.2404 52.7944 79.8062 52.7944H77.0974C77.0974 52.7944 76.9302 52.7776 76.8466 52.7776H31.032C29.6442 52.7776 28.5239 53.9019 28.5239 55.2948C28.5239 56.6877 29.6442 57.812 31.032 57.812H50.545C48.8395 60.0104 47.8196 62.7625 47.8196 65.7496C47.8196 71.7909 51.9663 76.8589 57.551 78.2853C55.9291 80.3494 54.9593 82.9338 54.9593 85.7531C54.9593 91.8783 59.524 96.9295 65.4097 97.735C63.9216 99.7488 63.0354 102.232 63.0354 104.917C63.0354 111.58 68.4361 117.017 75.091 117.017H90.6077C92.4303 117.017 94.1525 116.597 95.7075 115.859H111.392C123.731 115.859 132.058 113.51 138.429 108.156L139.348 107.3C143.529 103.86 146.672 102.568 150.986 102.568H158.009C159.397 102.568 160.517 101.444 160.517 100.051C160.517 98.658 159.397 97.5336 158.009 97.5336L157.992 97.5168ZM52.8358 65.7328C52.8358 61.3697 56.3805 57.812 60.7279 57.812H79.8062C84.1536 57.812 87.6983 61.3697 87.6983 65.7328C87.6983 70.096 84.1536 73.6537 79.8062 73.6537H60.7279C56.3805 73.6537 52.8358 70.096 52.8358 65.7328ZM59.9755 85.7531C59.9755 81.8598 63.1357 78.6881 67.0149 78.6881H85.324C89.2032 78.6881 92.3634 81.8598 92.3634 85.7531C92.3634 89.6463 89.2032 92.818 85.324 92.818H67.0149C63.1357 92.818 59.9755 89.6463 59.9755 85.7531ZM90.591 111.966H75.0742C71.195 111.966 68.0348 108.794 68.0348 104.901C68.0348 101.007 71.195 97.8357 75.0742 97.8357H90.591C94.4702 97.8357 97.6304 101.007 97.6304 104.901C97.6304 108.794 94.4702 111.966 90.591 111.966Z"
        fill="black"
      />
      <path
        id="Vector_5"
        d="M36.9343 74.0229C33.9915 67.3942 27.8215 60.0104 27.5707 59.6915C26.6177 58.5504 24.6948 58.5504 23.7417 59.6915C23.4742 60.0104 17.321 67.3774 14.3781 74.0229C13.3916 75.8185 12.8398 77.8323 12.7729 79.8628C12.7729 80.0138 12.7729 80.1481 12.7729 80.2991C12.7729 87.4312 18.5583 93.2544 25.6813 93.2544C32.8043 93.2544 38.5896 87.448 38.5896 80.2991C38.5896 80.1481 38.5896 80.0138 38.5896 79.8628C38.5228 77.8155 37.971 75.8017 36.9845 74.0229H36.9343ZM33.3728 81.7591C32.6873 85.4342 29.4769 88.2199 25.6311 88.2199C21.2838 88.2199 17.739 84.6623 17.739 80.2991V80.0474C17.7891 78.772 18.1403 77.4966 18.7589 76.3891C18.8091 76.3219 18.8425 76.238 18.876 76.1541C19.3943 74.9794 20.0464 73.7544 20.7654 72.5293C22.3539 69.8107 24.2433 67.1593 25.6311 65.3469C27.6209 67.9648 30.6975 72.2944 32.3863 76.1373C32.4197 76.2212 32.4532 76.2884 32.5033 76.3723C33.1387 77.4798 33.4898 78.7384 33.5233 80.0306C33.5233 80.0642 33.5233 80.081 33.5233 80.1145V80.2823C33.5233 80.7858 33.4731 81.2724 33.3728 81.7423V81.7591Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1179_2979">
        <rect width="160" height="117" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
