import { Box, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockProps } from "@/types/Block";

import { Finder } from "../finder/Finder";
import { BlockColumns } from "./BlockColumns";
import { BlockGroup } from "./BlockGroup";
import { Banner } from "./blocks/Banner";
import { BannerNewsletterSignup } from "./blocks/BannerNewsletterSignup";
import { BarChart } from "./blocks/BarChart";
import { BmiCalculator } from "./blocks/BmiCalculator";
import { ButtonBlock } from "./blocks/ButtonBlock";
import { CheckboxForm } from "./blocks/CheckboxForm";
import { CollapseItems } from "./blocks/CollapseItems";
import { ContactOptions } from "./blocks/ContactOptions";
import { Disclaimer } from "./blocks/Disclaimer";
import { Divider } from "./blocks/Divider";
import { ExitModal } from "./blocks/ExitModal";
import { Footer } from "./blocks/Footer";
import { Hero } from "./blocks/Hero";
import { IconBlock } from "./blocks/IconBlock";
import { IconCards } from "./blocks/IconCards";
import { ISIPanel } from "./blocks/ISIPanel";
import { LocationFinderBanner } from "./blocks/LocationFinderBanner";
import { MediaAndText } from "./blocks/MediaAndText";
import { MresviaEfficacy } from "./blocks/MresviaEfficacy";
import { MresviaNestedTextAndCard } from "./blocks/MresviaNestedTextAndCard";
import { MresviaTextAndMedia } from "./blocks/MresviaTextAndMedia";
import { MultiVideoPlayer } from "./blocks/MultiVideoPlayer";
import { NavigationBanner } from "./blocks/NavigationBanner";
import { ProductHero } from "./blocks/ProductHero";
import { ProductsFooter } from "./blocks/ProductsFooter";
import { SoftGatingModal } from "./blocks/SoftGatingModal";
import { Stats } from "./blocks/Stats";
import { TableBlock } from "./blocks/Table";
import { TableUI } from "./blocks/TableUI";
import { Text } from "./blocks/Text";
import { TextAndList } from "./blocks/TextAndList";
import { TextWithContentStrip } from "./blocks/TextWithContentStrip";
import { TextWithIconCards } from "./blocks/TextWithIconCards";
import { TextWithInfoCards } from "./blocks/TextWithInfoCards";
import { TextWithTitleCards } from "./blocks/TextWithTitleCards";
import { TimeSeriesChart } from "./blocks/TimeSeriesChart";
import { TitleCards } from "./blocks/TitleCards";
import { BlockTransition } from "./BlockTransition";
import { LabelsFooter } from "./labels/LabelsFooter";
import { CountryModal } from "./LocaleSelector/CountryModal";
import { LanguageModal } from "./LocaleSelector/LanguageModal";
import { SplitButton } from "./LocaleSelector/SplitButton";
import { PageHeader } from "./PageHeader";
import { BasicVideoPlayer } from "./rsv/BasicVideoPlayer";
import { EligibilityQuiz } from "./stayontrack/quiz/EligibilityQuiz";
import { TwoColBlock } from "./TwoColBlock";

const BlockComponent: React.FC<BlockProps> = (props) => {
  switch (props.component) {
    case "Banner":
      const { props: bannerProps } = props;
      return <Banner {...bannerProps} />;
    case "BannerNewsletterSignup":
      const { props: bannerNewsletterSignupProps } = props;
      return <BannerNewsletterSignup {...bannerNewsletterSignupProps} />;
    case "BarChart":
      const { props: barChartProps } = props;
      return <BarChart {...barChartProps} />;
    case "BasicVideoPlayer":
      const { props: basicVideoPlayerProps } = props;
      return <BasicVideoPlayer {...basicVideoPlayerProps} />;
    case "BmiCalculator":
      const { props: bmiCalculatorProps } = props;
      return <BmiCalculator {...bmiCalculatorProps} />;
    case "ButtonBlock":
      const { props: buttonBlockProps } = props;
      return <ButtonBlock {...buttonBlockProps} />;
    case "CollapseItems":
      const { props: collapseItemsProps } = props;
      return <CollapseItems {...collapseItemsProps} />;
    case "Columns":
      const { props: columnsProps } = props;
      return <BlockColumns {...columnsProps} />;
    case "ContactOptions":
      const { props: contactOptionsProps } = props;
      return <ContactOptions {...contactOptionsProps} />;
    case "CountryModal":
      const { props: countryModalProps } = props;
      return <CountryModal {...countryModalProps} />;
    case "PageHeader":
      const { props: pageHeader } = props;
      return <PageHeader {...pageHeader} />;
    case "Disclaimer":
      const { props: disclaimerProps } = props;
      return <Disclaimer {...disclaimerProps} />;
    case "Divider":
      const { props: dividerProps } = props;
      return <Divider {...dividerProps} />;
    case "ExitModal":
      const { props: exitModalProps } = props;
      return <ExitModal {...exitModalProps} />;
    case "BlockGroup":
      const { props: blockGroupProps } = props;
      return <BlockGroup {...blockGroupProps} />;
    case "EligibilityQuiz":
      const { props: eligibilityQuizProps } = props;
      return <EligibilityQuiz {...eligibilityQuizProps} />;
    case "Finder":
      const { props: finderProps } = props;
      return <Finder {...finderProps} />;
    case "Footer":
      const { props: footerProps } = props;
      return <Footer {...footerProps} />;
    case "Hero":
      const { props: heroProps } = props;
      return <Hero {...heroProps} />;
    case "IconBlock":
      const { props: iconBlockProps } = props;
      return <IconBlock {...iconBlockProps} />;
    case "IconCards":
      const { props: iconCardsProps } = props;
      return <IconCards {...iconCardsProps} />;
    case "ISIPanel":
      const { props: isiPanelProps } = props;
      return <ISIPanel {...isiPanelProps} />;
    case "LabelsFooter":
      const { props: LabelsFooterProps } = props;
      return <LabelsFooter {...LabelsFooterProps} />;
    case "LanguageModal":
      const { props: languageModalProps } = props;
      return <LanguageModal {...languageModalProps} />;
    case "LocationFinderBanner":
      const { props: vaccineBannerProps } = props;
      return <LocationFinderBanner {...vaccineBannerProps} />;
    case "MediaAndText":
      const { props: mediaAndTextProps } = props;
      return <MediaAndText {...mediaAndTextProps} />;
    case "MresviaEfficacy":
      const { props: mresviaEfficacyProps } = props;
      return <MresviaEfficacy {...mresviaEfficacyProps} />;
    case "MresviaNestedTextAndCard":
      const { props: mresviaNestedTextAndCardProps } = props;
      return <MresviaNestedTextAndCard {...mresviaNestedTextAndCardProps} />;
    case "MresviaTextAndMedia":
      const { props: mresviaTextAndMediaProps } = props;
      return <MresviaTextAndMedia {...mresviaTextAndMediaProps} />;
    case "MultiVideoPlayer":
      const { props: multiVideoPlayerProps } = props;
      return <MultiVideoPlayer {...multiVideoPlayerProps} />;
    case "NavigationBanner":
      const { props: navigationBannerProps } = props;
      return <NavigationBanner {...navigationBannerProps} />;
    case "ProductHero":
      const { props: productHeroProps } = props;
      return <ProductHero {...productHeroProps} />;
    case "ProductsFooter":
      const { props: productsFooterProps } = props;
      return <ProductsFooter {...productsFooterProps} />;
    case "SplitButton":
      const { props: SplitButtonBlockProps } = props;
      return <SplitButton {...SplitButtonBlockProps} />;
    case "Stats":
      const { props: statsProps } = props;
      return <Stats {...statsProps} />;
    case "SoftGateModal":
      const { props: softGateModalProps } = props;
      return <SoftGatingModal {...softGateModalProps} />;
    case "CheckboxForm":
      const { props: checkboxFormProps } = props;
      return <CheckboxForm {...checkboxFormProps} />;
    case "Table":
      const { props: tableProps } = props;
      return <TableBlock {...tableProps} />;
    case "TableUI":
      const { props: tableUIProps } = props;
      return <TableUI {...tableUIProps} />;
    case "Text":
      const { props: textProps } = props;
      return <Text {...textProps} />;
    case "TextAndList":
      const { props: textAndListProps } = props;
      return <TextAndList {...textAndListProps} />;
    case "TextWithContentStrip":
      const { props: textWithContentStripPrps } = props;
      return <TextWithContentStrip {...textWithContentStripPrps} />;
    case "TextWithIconCards":
      const { props: textWithIconCardsProps } = props;
      return <TextWithIconCards {...textWithIconCardsProps} />;
    case "TextWithInfoCards":
      const { props: TextWithInfoCardsProps } = props;
      return <TextWithInfoCards {...TextWithInfoCardsProps} />;
    case "TextWithTitleCards":
      const { props: textWithTitleCardsProps } = props;
      return <TextWithTitleCards {...textWithTitleCardsProps} />;
    case "TimeSeriesChart":
      const { props: timeSeriesChartProps } = props;
      return <TimeSeriesChart {...timeSeriesChartProps} />;
    case "TitleCards":
      const { props: titleCardsProps } = props;
      return <TitleCards {...titleCardsProps} />;
    case "TwoColBlock":
      const { props: TwoColBlockProps } = props;
      return <TwoColBlock {...TwoColBlockProps} />;
    case "Navigation":
      return null;
  }
};

export const Block: React.FC<BlockProps> = (props) => {
  const { block } = props;

  if (block?.transition) {
    return (
      <BlockTransition {...props}>
        <Box
          id={block?.id}
          sx={{
            boxShadow: block?.shadow,
            minHeight: block?.minHeight,
            pb: block?.spacingBottom,
            pt: block?.spacingTop
          }}
        >
          <BlockComponent {...props} />
        </Box>
      </BlockTransition>
    );
  }

  return (
    <ThemeOverride mode={block?.themeMode || "light"}>
      <Box
        id={block?.id}
        sx={{
          backgroundColor: block?.backgroundColor || "background01",
          backgroundImage: block?.backgroundImage,
          backgroundPosition: block?.backgroundPosition,
          backgroundRepeat: block?.backgroundRepeat,
          backgroundSize: block?.backgroundSize,
          boxShadow: block?.shadow,
          minHeight: block?.minHeight,
          pb: block?.spacingBottom,
          pt: block?.spacingTop,
          height: block?.height,
          flexDirection: block?.flexDirection,
          display: block?.display,
          flexGrow: block?.flexGrow
        }}
      >
        <BlockComponent {...props} />
      </Box>
    </ThemeOverride>
  );
};
