import React from "react";

export const DecreasedMuscle = () => (
  <svg height="160" width="160" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#9121a6"
      d="M87.1 0q2.43.45 4.87.82 24.82 3.81 43.11 20.85Q151.65 37.1 157.73 60q.87 3.29 2.27 12.67v14.7q-.42 2.02-.72 4.11-2.45 17.3-11.98 31.89-16.9 25.89-46.79 34.25-2.91.82-13.02 2.38H72.41q-2.96-.5-5.91-1.01C35.14 153.54 10.47 130.94 2.29 100Q1.33 96.4 0 87.09V73.01Q4.75 31.84 39.21 10.9 54 1.91 73.02 0H87.1Zm67.05 80A74.14 74.14 0 0 0 80.01 5.86 74.14 74.14 0 0 0 5.87 80a74.14 74.14 0 0 0 74.14 74.14A74.14 74.14 0 0 0 154.15 80Z"
    />
    <path
      fill="#cfb2de"
      d="M89.03 33.75a13.44 13.44 0 0 1-13.44 13.44 13.44 13.44 0 0 1-13.44-13.44 13.44 13.44 0 0 1 13.44-13.44 13.44 13.44 0 0 1 13.44 13.44Zm-4.82 0a8.62 8.62 0 0 0-8.62-8.62 8.62 8.62 0 0 0-8.62 8.62 8.62 8.62 0 0 0 8.62 8.62 8.62 8.62 0 0 0 8.62-8.62Z"
    />
    <path
      fill="#9121a6"
      d="M50.44 36.27c-2.67.09-5.72 2.27-7.35 4.16q-1.15 1.33-2.82.81-.34-.11-.52-.38-.99-1.53.16-2.89c3.08-3.64 7.64-6.19 12.66-5.37q.59.1.77.68.39 1.21-.02 2.35-.2.56-.79.58-1.03.02-2.09.06ZM59.08 44.57q-4.12-1.21-8.7 1.78-5.32 3.48-8.52 7.6-.92 1.18-2.26.51-.35-.17-.63-.46-1.32-1.32-.11-2.75c5.07-5.99 13.12-12.66 21.3-10.41a1.63 1.63 0 0 1 1.13 1.08q.27.86-.06 1.64-.63 1.46-2.15 1.01Z"
    />
    <path
      fill="#cfb2de"
      d="M87.56 70.94q.01 1.81-.21 3.59-.19 1.62.03 3.64.3 2.83.34 5.51a1.41 1.41 0 0 0 1.07 1.34q8.16 2 16.22 4.19c1.63.44 3.36 1.71 4.2 3.24q4.32 7.92 9.71 17.84c2.37 4.37.66 9.64-4.08 11.35q-6.56 2.36-10.17-4.1-3.59-6.41-7.43-13.56a1.03 1-6.3 0 0-.66-.51L82.86 100q-.83-.2-.4.53 2.81 4.77 5.2 9.35 1.46 2.8.59 6-2.28 8.37-4.44 16.66c-1.41 5.45-7.61 7.5-12.25 4.66-3.29-2.02-3.99-6.23-2.96-9.85q1.71-6.05 3.2-12.21.16-.64-.17-1.22-3.97-7.03-7.93-14.1-4.15-7.42-4.58-17.16-.33-7.5.22-14.18a.15.15 0 0 0-.24-.14q-3.07 2.2-5.82 4.43c-1.45 1.18-3.58 2.13-5.49 1.72q-6.84-1.45-14.31-2.81c-4.04-.73-6.38-3.27-6.06-7.71.31-4.37 4.15-6.63 8.37-5.85q5.02.93 10.49 2.15.52.11.94-.21 4.55-3.46 8.85-6.99c4.39-3.6 8.76-4.95 14.15-5.24q5.64-.31 10.63.05 7.84.57 11.65 5.7 3.18 4.27 6.32 8.47.34.46.81.13 2.65-1.91 5.14-3.88c3.87-3.06 9.21-3.1 11.55 1.78 1.44 3 .5 6.77-2.26 8.69q-6.02 4.19-11.9 8.64c-3.59 2.72-7.95 1.74-10.56-1.83q-1.77-2.43-3.43-4.83-.62-.91-.61.19Zm-8.23 23.21Q87.8 96.29 97 98.61c1.81.46 3.28.64 4.24 2.48q4.35 8.29 8.22 14.99a2.87 2.86-33 0 0 4.17.88l.34-.25q2.08-1.52.85-3.79l-9.3-17.17a4.66 4.64-7 0 0-2.96-2.3l-17.49-4.41q-.45-.12-.72-.5c-2.35-3.35-1.78-14.29-1.43-18.85q.24-3.1 1.16-6.24c.41-1.39 1.85-1.75 3.09-1.48q.5.1.81.52l8.18 10.89q1.15 1.53 2.7.4l12.48-9.14q1.36-1 .57-2.49l-.23-.45a1.63 1.63 0 0 0-2.4-.56l-8.53 6.2q-2.61 1.89-4.56-.67-3.61-4.74-7.79-10.43c-2.04-2.79-5.82-3.57-9.14-3.69-5.81-.2-13.47-.65-18.06 2.83q-6.05 4.59-12.12 9.47a2.22 2.2 31 0 1-1.84.45l-12.88-2.6q-1.69-.34-2.01 1.35l-.07.37q-.38 1.98 1.6 2.38l14.62 2.95q.63.12 1.13-.28 5.48-4.35 11.1-8.73c2.05-1.59 5.27.14 4.57 2.71q-2.18 7.97-1.44 18.97.58 8.64 3.84 14.6 4.07 7.47 8.76 15.51a3.62 3.59 37.7 0 1 .36 2.72l-3.78 14.18q-.68 2.55 1.58 3.92.36.22.79.28 2.97.39 3.77-2.5 2.13-7.79 4.37-16.51c.48-1.87-.48-3.19-1.36-4.73q-3.18-5.56-6.3-11.3a3.07 3.07 0 0 1 3.44-4.44Z"
    />
    <path
      fill="#9121a6"
      d="m127.73 85.51-.38.19q-1.56.76-2.39-.75c-1.96-3.57-5.2-4.5-8.88-5.36q-1.35-.31-1.23-1.69l.05-.66q.13-1.59 1.72-1.4c4.82.59 10.22 2.87 11.88 7.8q.43 1.28-.77 1.87ZM47.66 81.42c2.35.61 4.94-.3 6.66-1.95a1.77 1.76 37.2 0 1 2.07-.28c1.05.57 1.23 2.02.39 2.92-5.74 6.15-15.03 2.23-20.31-2.44q-1.09-.96-.46-2.28.14-.28.42-.42 1.57-.78 2.93.31 3.66 2.92 8.3 4.14ZM104.4 85.85q-1.43-.22-1.35-1.67.01-.33.1-.62.46-1.61 2.13-1.42c6.54.75 20.38 4.8 20.21 13.38q-.01.56-.45.9-1.06.82-2.21.43a1.05 1.04-89.6 0 1-.66-.68c-2.03-6.8-11.05-9.3-17.77-10.32ZM48.64 87.8c2.14.01 3.01-1.56 4.98-1.63q.76-.03 1.04.67.38.95.21 2.03-.1.62-.67.87-.65.29-1.22.68c-3.1 2.09-8.85 1-11.81-.54q-1.28-.67-1.22-2.1.03-.88 1.33-1.31a1.5 1.48 46.9 0 1 1.03.04q3.13 1.29 6.33 1.29ZM83.75 136.4q7.15-3.53 10.38-10.42.8-1.72 2.66-1.33.96.2 1.18 1.7a1.63 1.54 54.3 0 1-.12.89q-3.7 8.57-12.5 12.77a1.7 1.64-50.4 0 1-1.09.12q-1.75-.39-1.86-1.24-.21-1.72 1.35-2.49ZM89.74 143.85q5.17-2.54 7.5-8a1.65 1.65 0 0 1 .86-.86q1.48-.62 2.44.64a1.55 1.54-55.7 0 1 .25 1.35q-1.6 5.52-6.52 8.76-.96.64-2.05 1.04-2.95 1.08-3.49-.09-.89-1.91 1.01-2.84Z"
    />
  </svg>
);
