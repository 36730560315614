import { Grid, GridColumn, Text } from "@modernatx/ui-kit-react";

import { DisclaimerProps } from "@/types/Block";

export const Disclaimer = ({ disclaimerSection }: DisclaimerProps) => {
  return (
    <Grid>
      <GridColumn
        sx={{
          borderRadius: "medium",
          bg: "backgroundNeutral02",
          my: 5
        }}
      >
        <Text as="p" size="xs" sx={{ color: "text02", p: 8 }}>
          {disclaimerSection}
        </Text>
      </GridColumn>
    </Grid>
  );
};
