import React from "react";

export const KidneyDiseaseIcon = () => (
  <svg
    width="161"
    height="121"
    viewBox="0 0 161 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Advanced Kidney Disease 1" clipPath="url(#clip0_1179_3018)">
      <g id="Group">
        <path
          id="Vector"
          d="M155.402 39.3213C154.351 34.2904 152.695 29.6499 150.578 25.501C141.492 7.9798 127.322 4.45244 117.4 4.43799C114.88 4.59701 112.735 5.01624 110.949 5.66678C133.572 2.89115 166.044 37.6588 144.372 70.7495C143.782 71.6458 139.793 77.2838 139.735 78.5415C154.265 69.6941 158.095 52.0429 155.417 39.3357L155.402 39.3213Z"
          fill="#EBB6DB"
        />
        <path
          id="Vector_2"
          d="M117.703 33.7701C118.725 33.1485 119.042 31.8041 118.423 30.7921C117.803 29.7657 116.464 29.4477 115.456 30.0693L97.7585 40.9405C91.8544 44.5691 84.5104 44.5691 78.6063 40.9405L60.9085 30.0693C59.8861 29.4477 58.5613 29.7657 57.9421 30.7921C57.3229 31.8185 57.6397 33.1485 58.6621 33.7701L76.3599 44.6413C80.0175 46.8821 84.1072 48.0097 88.1968 48.0097C92.2864 48.0097 96.3761 46.8821 100.034 44.6413L117.731 33.7701H117.703Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M72.9184 50.6262L57.107 40.926C56.0846 40.3044 54.7598 40.6224 54.1406 41.6488C53.5214 42.6752 53.8382 44.0052 54.8606 44.6268L70.6575 54.3271C76.0576 57.652 79.4128 63.6514 79.4128 70.0123V118.831C79.4128 120.031 80.3776 121 81.5729 121C82.7681 121 83.7329 120.031 83.7329 118.831V70.0123C83.7329 62.1624 79.5856 54.7318 72.9184 50.6262Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M121.476 44.6268C122.498 44.0052 122.815 42.6608 122.196 41.6488C121.576 40.6224 120.237 40.3044 119.229 40.926L103.418 50.6262C96.7505 54.7318 92.6033 62.1624 92.6033 70.0123V118.831C92.6033 120.031 93.5681 121 94.7633 121C95.9585 121 96.9233 120.031 96.9233 118.831V70.0123C96.9233 63.6514 100.279 57.652 105.679 54.3271L121.476 44.6268Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M154.222 69.94C160.299 60.0229 161.623 47.9808 159.622 38.4395C154.927 16.0899 139.418 0.65052 121.015 -1.75862e-05C112.116 -0.187951 105.996 1.93714 102.784 6.30297C99.9043 10.2351 99.5443 15.8153 101.748 22.4508C102.122 23.5928 103.346 24.2 104.484 23.8241C105.621 23.4483 106.226 22.2195 105.852 21.0774C104.642 17.4199 103.72 12.3457 106.269 8.87621C107.306 7.47394 108.89 6.40416 110.949 5.65243C112.735 5.00189 114.88 4.58266 117.4 4.42364C118.509 4.35136 119.661 4.30799 120.9 4.3369C133.226 4.75614 144.127 12.8373 150.578 25.5011C152.695 29.65 154.351 34.2906 155.402 39.3214C158.081 52.0285 154.25 69.6798 139.721 78.5271C139.634 78.5849 139.548 78.6428 139.461 78.7006C131.613 82.8351 123.405 84.0061 117.501 81.8232C113.527 80.3631 110.661 77.4573 108.933 73.1638C107.464 69.6075 106.73 65.4874 106.658 60.2253C106.644 59.0399 105.679 58.0858 104.498 58.0858H104.469C103.274 58.1002 102.324 59.0833 102.338 60.2831C102.424 66.1235 103.245 70.7496 104.93 74.7974C107.076 80.1751 110.906 84.0061 116.004 85.8854C118.466 86.7962 121.202 87.2443 124.082 87.2443C129.036 87.2443 134.45 85.8999 139.865 83.3122C140.426 83.0375 141.002 82.7628 141.564 82.4737C147.079 79.1921 151.226 74.8118 154.222 69.9111V69.94Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M69.0447 58.0998H69.0159C67.8351 58.0998 66.8703 59.0539 66.8559 60.2393C66.7839 65.5159 66.0495 69.6216 64.5662 73.2067C62.7374 77.7894 59.5838 80.7674 55.1629 82.1119C54.8893 67.2073 42.7356 55.1796 27.8315 55.1796C24.6058 55.1796 21.4666 55.7434 18.4426 56.871C17.0458 51.1318 16.9162 44.9879 18.1114 39.3354C22.4026 18.9664 36.27 4.91474 52.5997 4.35094C59.9726 4.19192 64.9262 5.7243 67.2591 8.89025C69.8079 12.3598 68.8863 17.4484 67.6767 21.0914C67.3023 22.2335 67.9071 23.4623 69.0447 23.8382C70.1823 24.214 71.4063 23.6069 71.7807 22.4648C73.9839 15.8293 73.6239 10.2492 70.7439 6.31701C67.5183 1.95118 61.427 -0.159453 52.4701 -0.000432277C34.0955 0.650105 18.5866 16.0895 13.8921 38.4391C12.4953 45.0891 12.7113 52.057 14.4825 58.707C5.92885 63.5354 0.5 72.7297 0.5 82.6179C0.5 97.7537 12.7689 110.071 27.8459 110.071C41.3532 110.071 52.8877 99.8932 54.8461 86.6367C61.2974 85.1911 66.0495 81.1578 68.5839 74.8403C70.2831 70.7636 71.1039 66.1376 71.1903 60.2972C71.2047 59.0973 70.2543 58.1143 69.0591 58.0998H69.0447ZM50.7565 84.6417C49.7341 96.4671 39.6684 105.734 27.8459 105.734C15.1593 105.734 4.82004 95.3684 4.82004 82.6179C4.82004 73.7705 9.9753 65.6027 17.953 61.7717C21.0922 60.2683 24.4186 59.5021 27.8315 59.5021C40.518 59.5021 50.8573 69.8673 50.8573 82.6179C50.8573 83.2973 50.8141 83.9767 50.7565 84.6417Z"
          fill="black"
        />
      </g>
      <path
        id="Vector_7"
        d="M36.3564 84.5843C35.5068 83.7314 34.1388 83.7314 33.3036 84.5843L29.9915 87.9093L30.0203 72.094C30.0203 70.8941 29.0555 69.9255 27.8603 69.9255C26.6651 69.9255 25.7003 70.8941 25.7003 72.094L25.6715 87.9382L22.3739 84.6277C21.5242 83.7748 20.1562 83.7748 19.321 84.6277C18.4858 85.4806 18.4714 86.854 19.321 87.6924L26.3051 94.7038C26.5067 94.9062 26.7515 95.0652 27.0107 95.1808H27.0395C27.2843 95.282 27.5435 95.3399 27.8171 95.3399C28.0907 95.3399 28.3643 95.282 28.6091 95.1808C28.6091 95.1808 28.6235 95.1808 28.6379 95.1808C28.9115 95.0652 29.1419 94.9062 29.3435 94.7038L36.342 87.678C37.1916 86.8251 37.1916 85.4517 36.342 84.6132L36.3564 84.5843Z"
        fill="#EBB6DB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1179_3018">
        <rect width="160" height="121" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
