import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { MresviaEfficacyProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const MresviaEfficacy: React.FC<MresviaEfficacyProps> = ({ text, cards }) => {
  return (
    <Grid sx={{ justifyContent: "center" }}>
      <GridColumn size={[4, 8, 6]}>
        <BlockText {...text} />
      </GridColumn>
      <GridColumn size={[4, 8, 6]}>
        <Grid sx={{ justifyContent: "space-around" }}>
          {cards.map((card, index) => (
            <GridColumn
              key={index}
              size={[4, 8, 3]}
              sx={{
                mb: index === 0 ? [0, 0, 28] : [0, 0, 8],
                mt: index === 1 ? [5, 5, 15] : [5, 5, 0]
              }}
            >
              <Box
                sx={{
                  borderRadius: "medium",
                  boxShadow: "boxShadow01",
                  display: "flex",
                  flexDirection: "column",
                  p: 5,
                  textAlign: "center"
                }}
              >
                <BlockText {...card} />
              </Box>
            </GridColumn>
          ))}
        </Grid>
      </GridColumn>
    </Grid>
  );
};
