import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { Language } from "@/types/Language";

export const useAvailableLanguages = () => {
  const { alternates = [], language, country: countryCode, isProduction } = useExperience();

  const availableLanguages = React.useMemo(() => {
    const languagesSet = new Set<Language>();
    alternates.forEach((alt) => {
      if ((isProduction && alt.isLiveInProduction) || !isProduction) {
        if (alt.language && alt.language !== language && alt.country === countryCode) {
          languagesSet.add(alt.language);
        }
      }
    });
    return languagesSet;
  }, [alternates, language, countryCode, isProduction]);

  return availableLanguages;
};
