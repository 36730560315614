import React from "react";

import { ExitModal } from "@/types/Block";

export interface ExitModalContextProps {
  exitModal?: ExitModal;
  isExitModalVisible: boolean;
  getIsExternalLink: (url: string) => boolean;
  openExitModal: (open: boolean) => void;
  exitLink: string;
}
export const ExitModalContext = React.createContext<ExitModalContextProps>({
  exitModal: {
    buttonText: "Open External Link",
    text: [
      "[heading]You are about to leave Moderna's website[/heading]",
      "The content displayed on external websites is not controlled by Moderna and is not subject to our [link]Privacy Policy[/link]. We do not assume responsibility for the information or statements hosted outside of our web properties."
    ],
    privacyLink: "https://www.modernatx.com/en-US/privacy-statement"
  },
  isExitModalVisible: false,
  getIsExternalLink: () => false,
  openExitModal: () => {},
  exitLink: ""
});
export const ExitModalProvider: React.FC<{
  children: React.ReactNode;
  exitModal?: ExitModal;
}> = ({ children, exitModal }) => {
  const [isExitModalVisible, setExitModalVisible] = React.useState(false);
  const [exitLink, setExitLink] = React.useState("");
  const openExitModal = React.useCallback(
    (open: boolean) => setExitModalVisible(open),
    [setExitModalVisible]
  );
  const getIsExternalLink = React.useCallback((url: string) => {
    const urlLower = url.toLowerCase();
    if (
      urlLower.startsWith("/") ||
      urlLower.startsWith("mailto:") ||
      urlLower.startsWith("tel:") ||
      urlLower.startsWith("#")
    ) {
      return false;
    }
    try {
      const urlObject = new URL(url);
      if (isInternalDomain(urlObject.hostname.toLowerCase())) {
        return false;
      } else {
        setExitLink(url);
        return true;
      }
    } catch {
      return false;
    }
  }, []);
  const value = React.useMemo(
    () => ({ exitModal, isExitModalVisible, openExitModal, getIsExternalLink, exitLink }),
    [exitModal, isExitModalVisible, openExitModal, getIsExternalLink, exitLink]
  );
  return <ExitModalContext.Provider value={value}>{children}</ExitModalContext.Provider>;
};
export const useExitModal = () => React.useContext(ExitModalContext);

export const isInternalDomain = (domain: string) => {
  const internalDomains = [
    "modernatx.com",
    "disease.education",
    "takecarecovid19moderna.jp",
    "modernadirect.com",
    "modernadirect.ch",
    "report.moderna.convergehealthsafety.com",
    "moderna-epi-report.jp",
    "modernacovid19global.com",
    "covid19-evidenz.de",
    "modernacovid19global.com",
    "tools.modernamedinfo.com",
    "spikevax.com"
  ];
  const escapeRegex = (string: string) => string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const domainPattern = internalDomains.map(escapeRegex).join("|");
  const internalDomainRegex = new RegExp(`${domainPattern}`, "i");
  return internalDomainRegex.test(domain);
};
