import { Country } from "@/types/Country";
import { Language } from "@/types/Language";

export const countryCodeMap: Record<Country, Partial<Record<Language | "default", string>>> = {
  // The "default" here refers to the native name of the country in its primary language.
  at: { default: "Österreich" }, // Austria
  ae: { default: "United Arab Emirates" },
  au: { default: "Australia" },
  be: { de: "Belgien", fr: "Belgique", default: "België" }, // Belgium
  bg: { default: "Bulgaria" },
  ca: { default: "Canada" },
  ch: { default: "Switzerland" },
  cy: { default: "Κύπρος" }, // Cyprus
  cz: { default: "Česká republika" }, // Czech Republic
  de: { default: "Deutschland" }, // Germany
  dk: { default: "Danmark" }, // Denmark
  ee: { default: "Eesti" },
  es: { default: "España" }, // Spain
  fi: { default: "Suomi" }, // Finland
  fr: { default: "France" },
  gb: { default: "Great Britain" },
  gr: { default: "Ελλάδα" }, // Greece
  hr: { default: "Hrvatska" }, // Croatia
  hu: { default: "Magyarország" }, // Hungary
  ie: { default: "Ireland" },
  it: { default: "Italia" }, // Italy
  jp: { default: "Japan" },
  kr: { default: "South Korea" },
  lt: { default: "Lietuva" }, // Lithuania
  lu: { de: "Luxemburg", default: "Luxembourg" },
  lv: { default: "Latvija" }, // Latvia
  mt: { default: "Malta" },
  nl: { default: "Nederland" }, // Netherlands
  pl: { default: "Polska" }, // Poland
  pt: { default: "Portugal" },
  qa: { default: "Qatar" },
  ro: { default: "România" },
  se: { default: "Sverige" }, // Sweden
  sg: { default: "Singapore" },
  si: { default: "Slovenija" }, // Slovenia
  sk: { default: "Slovensko" }, // Slovakia
  us: { default: "United States" },
  no: { default: "Norge" }, // Norway
  is: { default: "Ísland" }, // Iceland
  tw: { default: "台灣" } // Taiwan
};
