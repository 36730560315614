import { Box } from "@modernatx/ui-kit-react";

import { useIcon } from "@/hooks/useIcon";
import { IconBlockProps } from "@/types/Block";

export const IconBlock: React.FC<IconBlockProps> = ({ icon }) => {
  const Icon = useIcon(icon);
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        height: 40
      }}
    >
      {Icon && <Icon />}
    </Box>
  );
};
