import { Button, Grid, GridColumn, Icon } from "@modernatx/ui-kit-react";

import { ButtonBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const ButtonBlock: React.FC<ButtonBlockProps> = ({
  download,
  variant,
  gridSize = [4, 4, 3],
  href,
  title,
  iconRight
}) => {
  return (
    <Grid>
      <GridColumn size={gridSize}>
        <Button variant={variant} href={href} download={download}>
          <BlockText text={title} />
          {iconRight && <Icon icon={iconRight} />}
        </Button>
      </GridColumn>
    </Grid>
  );
};
