import { Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { TwoColBlockProps } from "@/types/Block";

import { Block } from "./Block";

export const TwoColBlock: React.FC<TwoColBlockProps> = ({
  gridLeft = [4, 8, 6],
  gridRight = [4, 8, 6],
  blocksLeft,
  blocksRight
}) => {
  return (
    <Grid initial={true}>
      <GridColumn size={gridLeft}>
        {blocksLeft.map((block, i) => (
          <Block key={i} {...block} />
        ))}
      </GridColumn>
      <GridColumn size={gridRight}>
        {blocksRight.map((block, i) => (
          <Block key={i} {...block} />
        ))}
      </GridColumn>
    </Grid>
  );
};
