import React from "react";

export const VisionImpairment = () => (
  <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161 160">
    <path
      fill="#9121a6"
      d="M84.86 0q28.83 2.18 49.05 20.83c39.37 36.3 33.37 99.78-12.41 127.68q-16.24 9.9-36.02 11.49H74.62c-35.76-3.02-64.25-27.24-72.66-62.12Q.26 90.84.45 84.15c.18-6.36-.44-13.02.94-19.51C9.07 28.5 38.03 2.93 74.89 0h9.97Zm69.45 80.01a74.3 74.3 0 0 0-74.3-74.3 74.3 74.3 0 0 0-74.3 74.3 74.3 74.3 0 0 0 74.3 74.3 74.3 74.3 0 0 0 74.3-74.3Z"
    />
    <path
      fill="#cfb2de"
      d="m133.43 82.22 1.12-.03a.37.37 0 0 0 .32-.54q-7.22-13.81-15.77-30.6c-2.23-4.39-7.07-5.1-11.09-2.78q-2.23 1.29-4.45 2.16-2.24.88-3.69-1.21-.21-.3-.24-.68-.14-2.06 1.64-3.12 4.21-2.52 7.78-3.52c6.32-1.79 12.5 1.2 15.45 7.06q9.34 18.54 19.39 37.88c1.79 3.44-.2 7.72-3.43 9.61-2.05 1.2-4.59 1.02-6.89.99q-.59-.01-.82.53c-9.63 22.82-42.94 18.99-47.13-5.59a.84.84 0 0 0-.67-.68q-4.97-.97-9.84.02-.53.1-.62.63c-4.21 24.63-37.45 28.42-47.13 5.6q-.25-.58-.88-.58c-1.52.01-3.18.21-4.67-.17-5.81-1.47-7.52-6.74-4.85-11.87q9.35-17.96 17.58-34.29 2.71-5.38 5.15-7.09c6.66-4.64 13.37-2.22 19.54 1.69q1.6 1.02 1.28 2.89-.08.42-.32.75-1.4 1.96-3.66 1.11-2.14-.81-4.16-2.01c-4.49-2.65-9.19-1.66-11.6 3.11q-7.54 14.95-15.55 30.2a.32.32 0 0 0 .27.47l1.11.04q.52.03.73-.46c9.27-21.77 41.67-19.8 46.9 3.98q.14.63.77.5 4.93-.96 10.2.01.57.1.69-.47c4.98-23.56 38.02-25.99 46.89-3.96a.68.67 77.5 0 0 .65.42Zm-64.58 7.707A18.62 17.71.3 0 0 50.323 72.12 18.62 17.71.3 0 0 31.61 89.732a18.62 17.71.3 0 0 18.527 17.808A18.62 17.71.3 0 0 68.85 89.927Zm59.61-1.178a18.61 17.73-3.3 0 0-19.6-16.63A18.61 17.73-3.3 0 0 91.3 90.891a18.61 17.73-3.3 0 0 19.6 16.63 18.61 17.73-3.3 0 0 17.56-18.772ZM25.91 91.62l-.06-3.72a.25.25 0 0 0-.25-.25l-1.69.03a2.46 1.97-.8 0 0-2.43 2v.28a2.46 1.97-.8 0 0 2.49 1.94l1.69-.03a.25.25 0 0 0 .25-.25Zm108.34-3.38-.05 3.04q0 .53.53.59 1.48.17 2.63-.2a1.91 1.9-25.1 0 0 1.04-2.85q-.99-1.56-3.68-1.12-.46.07-.47.54Z"
    />
    <path
      fill="#9121a6"
      d="M50.25 80.83c-4.94.16-8.77 3.26-9.64 8.07q-.5 2.73-3.26 2.38-1.34-.17-2.16-1.8a2.48 2.47 38.8 0 1-.19-1.73q2.94-11.66 14.66-12.57 2.66-.21 3.33 2.37.12.44.03.9-.43 2.3-2.77 2.38ZM119.56 89.05c-.92-4.87-4.66-8.18-9.59-8.22q-2.58-.01-2.85-2.58-.02-.16 0-.32.25-2.5 2.75-2.67c7.64-.53 13.79 5.51 15.15 12.51q.5 2.6-2.01 3.44-.48.16-.98.11-2.08-.21-2.47-2.27Z"
    />
  </svg>
);
