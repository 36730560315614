import { Box, Grid, GridColumn, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { StatsProps } from "@/types/Block";

import { BlockText } from "../BlockText";

const Stat: React.FC<StatsProps["stats"][0]> = ({ percent, subtitle, title }) => {
  return (
    <Box sx={{ textAlign: "center", pb: 10 }}>
      <Box>
        <Text as="span" size="8xl" variant="bold" sx={{ color: "accent01" }}>
          {percent}
        </Text>
        <Text as="span" size="4xl" variant="bold" sx={{ color: "accent01" }}>
          %
        </Text>
      </Box>
      {title && (
        <Text as="p" size="xl" variant="bold" sx={{ pt: 2 }}>
          <BlockText {...title} />
        </Text>
      )}
      {subtitle && (
        <Text as="p" size="xs">
          <BlockText {...subtitle} />
        </Text>
      )}
    </Box>
  );
};

export const Stats: React.FC<StatsProps> = ({ stats }) => {
  return (
    <Grid>
      {stats.map((stat, i) => (
        <GridColumn key={i} size={[4, 8, 6]}>
          <Stat {...stat} />
        </GridColumn>
      ))}
    </Grid>
  );
};
