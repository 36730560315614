import { Box } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockIcon } from "@/types/BlockIcon";

interface IconContextState {
  icons: Partial<{
    [key in BlockIcon]: React.ComponentType;
  }>;
}

const IconPlaceholder: React.FC = () => <Box />;

const IconContext = React.createContext<IconContextState>({
  icons: {
    "adverse-event-clipboard": IconPlaceholder,
    "check-clipboard": IconPlaceholder,
    "circle-clipboard": IconPlaceholder,
    "circle-idea": IconPlaceholder,
    "circle-no-exclamation": IconPlaceholder,
    cost: IconPlaceholder,
    "file-checked": IconPlaceholder,
    "heart-failure": IconPlaceholder,
    "hold-heart": IconPlaceholder,
    "kidney-disease": IconPlaceholder,
    "pulmonary-disease": IconPlaceholder,
    asthma: IconPlaceholder,
    calendar: IconPlaceholder,
    clock: IconPlaceholder,
    diabetes: IconPlaceholder,
    fridge: IconPlaceholder,
    mrna: IconPlaceholder,
    snowflake: IconPlaceholder,
    syringe: IconPlaceholder,
    thermometer: IconPlaceholder,
    "thermometer-with-snowflake": IconPlaceholder,
    lungs: IconPlaceholder,
    fever: IconPlaceholder,
    "low-oxygen": IconPlaceholder,
    "chest-pain": IconPlaceholder,
    communication: IconPlaceholder
  }
});

export const IconProvider: React.FC<React.PropsWithChildren<IconContextState>> = ({
  children,
  icons
}) => {
  return <IconContext.Provider value={{ icons }}>{children}</IconContext.Provider>;
};

export const useIcon = (icon?: BlockIcon) => {
  const { icons } = React.useContext(IconContext);
  return icon ? icons[icon] : null;
};
