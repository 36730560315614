import { Box, Button, Grid, GridColumn, Icon, Link, Text } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { ReplaceText } from "@/components/rsv/ReplaceText";
import { MediaAndTextProps } from "@/types/Block";

import { BasicVideoPlayer } from "../rsv/BasicVideoPlayer";

export const MediaAndText: React.FC<MediaAndTextProps> = (props) => {
  const {
    buttonText,
    buttonHref,
    linkReplacementUrl,
    mediaFirst = true,
    text,
    title,
    titleSize,
    videoCaptions,
    videoSrc,
    imageSrc,
    videoPoster
  } = props;
  const router = useRouter();
  const replace = React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      heading: ({ children }) => (
        <Box as="strong" sx={{ display: "block", fontWeight: "bold", mb: -3 }}>
          {children}
        </Box>
      ),
      link: ({ children }) => (
        <Link href={linkReplacementUrl} target="_blank">
          {children}
        </Link>
      ),
      sup: (supProps) => <sup {...supProps} />
    }),
    [linkReplacementUrl]
  );
  if (!props) {
    return null;
  }

  const whatIsRsvText = text;

  return (
    <>
      <Grid sx={{ pt: 10 }}>
        <GridColumn size={[4, 8, 6]} sx={{ mb: [5, 5, 10], order: mediaFirst ? 0 : 2 }}>
          {videoSrc && (
            <BasicVideoPlayer src={videoSrc} captions={videoCaptions} poster={videoPoster} />
          )}
          {imageSrc && (
            <Box
              sx={{
                backgroundImage: `url(${imageSrc.source})`,
                borderRadius: "medium",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 78,
                position: "relative",
                textAlign: "center"
              }}
              role="img"
              aria-label={imageSrc.label || "image"}
            />
          )}
        </GridColumn>
        <GridColumn size={[4, 8, 6]} sx={{ pb: 10, order: 1 }}>
          <Text size={titleSize ? titleSize : "3xl"} variant="bold" sx={{ mb: 2 }}>
            {title}
          </Text>
          {whatIsRsvText?.map((textChunk, i) => (
            <Text
              key={i}
              as="p"
              sx={{
                color: "text02",
                my: 2,
                mb: i === whatIsRsvText.length - 1 ? 0 : undefined,
                mt: i === 0 ? 0 : undefined,
                whiteSpace: "pre-wrap"
              }}
            >
              <ReplaceText replace={replace}>{textChunk}</ReplaceText>
            </Text>
          ))}
          {buttonText && buttonHref && (
            <Grid>
              <GridColumn size={[4, 4, 3]}>
                <Button variant="secondary" sx={{ my: 4 }} onClick={() => router.push(buttonHref)}>
                  <Text variant="bold">{buttonText}</Text>
                  <Icon icon="arrow-right" />
                </Button>
              </GridColumn>
            </Grid>
          )}
        </GridColumn>
      </Grid>
    </>
  );
};
