import { Box, Text } from "@modernatx/ui-kit-react";

interface IconCardProps {
  children?: React.ReactNode;
  subtitle?: string;
  title?: string;
  icon?: React.JSX.Element;
}

const IconCard: React.FC<IconCardProps> = ({ children, subtitle, title, icon }) => {
  return (
    <Box
      sx={{
        bg: "background01",
        borderRadius: "medium",
        boxShadow: "boxShadow01",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: icon ? "center" : "unset",
        px: 8,
        py: 6,
        textAlign: icon ? "center" : "unset"
      }}
    >
      {icon && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: 40
          }}
        >
          {icon}
        </Box>
      )}
      {title && (
        <Text as="p" size="2xl" sx={{ mt: 5, mb: 1, color: "text01", fontWeight: "bold" }}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text as="p" sx={{ color: "text02" }}>
          {subtitle}
        </Text>
      )}
      {children}
    </Box>
  );
};

export default IconCard;
