import { Box, Button, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { MresviaTextAndMediaProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const MresviaTextAndMedia: React.FC<MresviaTextAndMediaProps> = ({
  text,
  button,
  image
}) => {
  return (
    <>
      <Grid sx={{ justifyContent: "center" }}>
        <GridColumn size={[4, 8, 6]} sx={{ mb: [9, 7, 0] }}>
          <BlockText text={text} />
          {button && (
            <Grid sx={{ pt: 4 }}>
              <GridColumn size={[4, 8, 3]}>
                <Button variant={button.variant} href={button.href} download={button.download}>
                  {button.title}
                </Button>
              </GridColumn>
            </Grid>
          )}
        </GridColumn>
        <GridColumn size={[4, 8, 6]}>
          <Box
            as="img"
            src={image.src}
            aria-label={image.label || "image"}
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              position: "relative",
              textAlign: "center"
            }}
          />
        </GridColumn>
      </Grid>
    </>
  );
};
