import { Box, Grid, GridColumn, Headline, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithIconCardsProps } from "@/types/Block";

import { DecreasedMuscle } from "../cmv/icons/DecreasedMuscle";
import { HearingLoss } from "../cmv/icons/HearingLoss";
import { LearningDisability } from "../cmv/icons/LearningDisability";
import { VisionImpairment } from "../cmv/icons/VisionImpairment";
import IconCard from "../rsv/IconCard";
import { DiabetesIcon } from "../rsv/icons/DiabetesIcon";
import { HeartFailureIcon } from "../rsv/icons/HeartFailureIcon";
import { KidneyDiseaseIcon } from "../rsv/icons/KidneyDiseaseIcon";
import { ObstructivePulmonaryDiseaseIcon } from "../rsv/icons/ObstructivePulmonaryDiseaseIcon";
import { ReplaceText } from "../rsv/ReplaceText";
import { TwoColumnText } from "../rsv/TwoColumnText";

enum Icons {
  LungDisease = "lungDiseaseIcon",
  HeartDisease = "heartDiseaseIcon",
  Diabetes = "diabetesIcon",
  KidneyOrLiverDisease = "kidneyDiseaseIcon",
  HearingLossIcon = "hearing-loss",
  VisionImpairmentIcon = "vision-impairment",
  LearningDisabilityIcon = "learning-disability",
  DecreasedMuscleIcon = "decreased-muscle"
}
const iconMap: Record<Icons | string, () => React.JSX.Element> = {
  [Icons.LungDisease]: ObstructivePulmonaryDiseaseIcon,
  [Icons.HeartDisease]: HeartFailureIcon,
  [Icons.Diabetes]: DiabetesIcon,
  [Icons.KidneyOrLiverDisease]: KidneyDiseaseIcon,
  [Icons.HearingLossIcon]: HearingLoss,
  [Icons.VisionImpairmentIcon]: VisionImpairment,
  [Icons.LearningDisabilityIcon]: LearningDisability,
  [Icons.DecreasedMuscleIcon]: DecreasedMuscle
};

export const TextWithIconCards: React.FC<TextWithIconCardsProps> = (props) => {
  const replace = React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      b: (boldProps) => <Box as="strong" {...boldProps} sx={{ fontWeight: "bold" }} />,
      sup: (supProps) => <sup {...supProps} />
    }),
    []
  );

  if (!props) {
    return null;
  }

  const { disclaimer, iconCards, text, title, isTextTwoColumns = true } = props;

  return (
    <Box sx={{ py: [5, 5, 10] }}>
      <Grid>
        <GridColumn>
          <Headline as="h2" level={4} sx={{ mb: 2 }} variant="bold">
            {title}
          </Headline>
          <TwoColumnText disable={!isTextTwoColumns}>
            {text?.map((textItem, i) => (
              <ReplaceText key={i} replace={replace}>
                {textItem}
              </ReplaceText>
            ))}
          </TwoColumnText>
          <ThemeOverride mode="light">
            {iconCards && (
              <Grid sx={{ mt: 5 }}>
                {iconCards.map((iconCard) => {
                  const IconComponenet = iconMap[iconCard.icon];
                  if (!IconComponenet) {
                    throw new Error(
                      `No icon found for cardTitle of: ${iconCard.cardTitle}\n\n You probably have a typo within your cardTitle property.`
                    );
                  } else {
                    return (
                      <GridColumn
                        key={iconCard.cardTitle}
                        size={[4, 4, 3]}
                        sx={{ display: "flex", mb: 5 }}
                      >
                        <IconCard
                          icon={<IconComponenet />}
                          title={iconCard.cardTitle}
                          subtitle={iconCard.subTitle}
                        />
                      </GridColumn>
                    );
                  }
                })}
              </Grid>
            )}
          </ThemeOverride>
          {disclaimer && (
            <Text size="md" sx={{ color: "text02" }}>
              {disclaimer}
            </Text>
          )}
        </GridColumn>
      </Grid>
    </Box>
  );
};
