import { Box, BoxSx } from "@modernatx/ui-kit-react";

import { useIcon } from "@/hooks/useIcon";
import { BlockIcon } from "@/types/BlockIcon";

interface TitleCardProps {
  children: React.ReactNode;
  icon?: BlockIcon;
  iconSize?: "md" | "lg";
  backgroundColor?: BoxSx["backgroundColor"];
  variant?: "shadow" | "border";
}

const TitleCard: React.FC<TitleCardProps> = ({
  children,
  icon,
  iconSize = "md",
  backgroundColor,
  variant = "shadow"
}) => {
  const Icon = useIcon(icon);

  return (
    <Box
      sx={{
        alignItems: "center",
        bg: "background01",
        border: variant === "border" ? "1px solid" : "none",
        borderColor: "stroke02",
        borderRadius: "medium",
        boxShadow: variant === "shadow" ? "boxShadow02" : "unset",
        color: "stroke01",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        fontWeight: "medium",
        justifyContent: Icon ? "start" : "center",
        minHeight: 35,
        pt: backgroundColor ? 0 : 5,
        px: backgroundColor ? 0 : 5,
        pb: 5,
        textAlign: "center"
      }}
    >
      {Icon && (
        <Box
          sx={{
            backgroundColor: backgroundColor || "inherit",
            height: iconSize === "md" ? 13 : backgroundColor ? 35 : 20,
            width: "100%",
            p: backgroundColor ? 5 : 0,
            mb: 3,
            // @ts-ignore
            "> *": {
              height: "100%"
            }
          }}
        >
          <Icon />
        </Box>
      )}
      <Box sx={{ px: 4 }}>{children}</Box>
    </Box>
  );
};

export default TitleCard;
