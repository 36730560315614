import { Box, Button, Grid, GridColumn, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { MresviaNestedTextAndCardProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const MresviaNestedTextAndCard: React.FC<MresviaNestedTextAndCardProps> = ({
  textOuter,
  textInner,
  button
}) => {
  return (
    <>
      <Grid>
        <GridColumn size={12}>
          <Box
            sx={{
              backgroundColor: "backgroundNeutral02",
              p: 8,
              display: "flex",
              flexDirection: ["column", "column", "row"],
              borderRadius: "iconButtonBorderRadiusSquare",
              gap: 5
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BlockText text={textOuter} />
            </Box>
            <Grid>
              <GridColumn push={[0, 0, 3]} size={[4, 8, 9]}>
                <ThemeOverride mode="dark">
                  <Box
                    sx={{
                      borderRadius: "iconButtonBorderRadiusSquare",
                      backgroundColor: "accent01",
                      p: 8
                    }}
                  >
                    <Grid>
                      <GridColumn size={12} sx={{ display: "flex", gap: 5 }}>
                        <BlockText {...textInner} />
                        {button && (
                          <Button
                            variant={button.variant}
                            download={button.download}
                            href={button.href}
                          >
                            {button.title}
                          </Button>
                        )}
                      </GridColumn>
                    </Grid>
                  </Box>
                </ThemeOverride>
              </GridColumn>
            </Grid>
          </Box>
        </GridColumn>
      </Grid>
    </>
  );
};
