import { Box, Grid, GridColumn, IconButton } from "@modernatx/ui-kit-react";
import React from "react";

import { useIcon } from "@/hooks/useIcon";
import { IconCardsProps } from "@/types/Block";
import { BlockIcon } from "@/types/BlockIcon";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";
import IconCard from "../rsv/IconCard";

const Card: React.FC<{ icon?: BlockIcon; text: BlockTextProps }> = ({ icon, text }) => {
  const Icon = useIcon(icon);

  return (
    <IconCard
      icon={
        icon ? (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              // @ts-ignore
              "> *": { height: "100%" }
            }}
          >
            {Icon && <Icon />}
          </Box>
        ) : undefined
      }
    >
      <BlockText {...text} />
    </IconCard>
  );
};

const getTotalWidth = (node: Element | null): number => {
  if (!node) {
    return 0;
  }
  return (
    parseInt(window.getComputedStyle(node).width, 10) +
    parseInt(window.getComputedStyle(node).marginLeft, 10) +
    parseInt(window.getComputedStyle(node).marginRight, 10)
  );
};

export const IconCards: React.FC<IconCardsProps> = ({ items }) => {
  const [scrollState, setScrollState] = React.useState({
    nextDisabled: false,
    prevDisabled: false
  });
  const scrollElement = React.useRef<HTMLElement | null>(null);
  const firstGridColumnElement = React.useRef<HTMLElement | null>(null);

  const handleClickNext = React.useCallback(() => {
    if (scrollElement.current) {
      const { clientWidth, scrollLeft, scrollWidth } = scrollElement.current;
      if (clientWidth >= scrollWidth / 2) {
        scrollElement.current.scrollTo({
          left: scrollLeft + clientWidth,
          behavior: "smooth"
        });
      } else {
        const columnWidth = getTotalWidth(firstGridColumnElement.current);
        scrollElement.current.scrollTo({
          left: scrollLeft + columnWidth,
          behavior: "smooth"
        });
      }
    }
  }, []);

  const handleClickPrev = React.useCallback(() => {
    if (scrollElement.current) {
      const { clientWidth, scrollLeft, scrollWidth } = scrollElement.current;
      if (clientWidth >= scrollWidth / 2) {
        scrollElement.current.scrollTo({
          left: scrollLeft - clientWidth,
          behavior: "smooth"
        });
      } else {
        const columnWidth = getTotalWidth(firstGridColumnElement.current);
        scrollElement.current.scrollTo({
          left: scrollLeft - columnWidth,
          behavior: "smooth"
        });
      }
    }
  }, []);

  const handleScroll = React.useCallback(() => {
    if (scrollElement.current) {
      const { clientWidth, scrollLeft, scrollWidth } = scrollElement.current;
      const scrollLeftCiel = Math.ceil(scrollLeft);
      const scrollMax = Math.floor(scrollWidth - clientWidth);
      const isScrollable = scrollWidth > clientWidth;
      const nextDisabled = !isScrollable || scrollLeftCiel >= scrollMax;
      const prevDisabled = !isScrollable || scrollLeftCiel <= 0;

      if (nextDisabled !== scrollState.nextDisabled || prevDisabled !== scrollState.prevDisabled) {
        setScrollState({ nextDisabled, prevDisabled });
      }
    }
  }, [scrollState]);

  React.useEffect(() => {
    handleScroll();
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("resize", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <Box
        ref={scrollElement}
        sx={{
          mt: -3,
          overflowY: "auto",
          py: 8,
          scrollbarWidth: "none",
          width: "100%",
          // @ts-ignore
          "::-webkit-scrollbar": {
            display: "none"
          }
        }}
        tabIndex={0}
        onScroll={handleScroll}
      >
        <Grid wrap={false}>
          {items.map((item, i) => (
            <GridColumn key={i} ref={i === 0 ? firstGridColumnElement : null} size={3}>
              <Card {...item} />
            </GridColumn>
          ))}
        </Grid>
      </Box>
      <Grid>
        <GridColumn
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            mt: -3
          }}
        >
          <IconButton
            aria-label="Go to previous page"
            disabled={scrollState.prevDisabled}
            icon="caret-left"
            shape="square"
            size="sm"
            onClick={handleClickPrev}
          />
          <IconButton
            aria-label="Go to next page"
            disabled={scrollState.nextDisabled}
            icon="caret-right"
            shape="square"
            size="sm"
            onClick={handleClickNext}
          />
        </GridColumn>
      </Grid>
    </>
  );
};
