import { Divider as UIKitDivider, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

export const Divider: React.FC = () => {
  return (
    <Grid>
      <GridColumn>
        <UIKitDivider />
      </GridColumn>
    </Grid>
  );
};
