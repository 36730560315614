import React from "react";

export const HearingLoss = () => (
  <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#9121a6"
      d="M84.9 0q2.41.38 4.84.67C128.2 5.31 156.87 36.19 160 74.54v10.84c-3.52 40.49-33.97 71.23-74.53 74.62H74.63q-33.94-2.96-54.88-27.25Q3.56 113.97.63 89.23.38 87.11 0 85.04v-9.92C3.3 34.27 34.09 3.16 75.01 0h9.89Zm69.41 80.01a74.3 74.3 0 0 0-74.3-74.3 74.3 74.3 0 0 0-74.3 74.3 74.3 74.3 0 0 0 74.3 74.3 74.3 74.3 0 0 0 74.3-74.3Z"
    />
    <path
      fill="#9121a6"
      d="M47.56 36.18c-4.35 4.38-7.84 9.6-10.26 15.31a2.97 2.97 0 0 1-2.65 1.8c-2.55.07-3.92-2.35-2.91-4.57q4.5-9.96 11.48-16.88 1.92-1.91 4.15-.38.51.35.8.92 1.05 2.12-.61 3.8Z"
    />
    <path
      fill="#cfb2de"
      d="M73.7 123.62q2.89-2.36 3.98-5.92c2.36-7.73 7.28-13.48 15-16.22 21.56-7.65 29.84-34.91 14.23-52.76-10.56-12.07-27.4-15.18-41.21-7.4q-16.36 9.22-17.19 29.4a2.55 2.54 71.5 0 1-.92 1.85q-1.19.97-2.34.74-2.91-.57-2.8-3.54c.9-24.71 21.75-42.09 46.18-38.23 9.14 1.45 18.08 6.87 23.87 14.24 15.41 19.6 9.27 47.6-12.56 59.31-2.92 1.57-6.2 2.29-8.93 4.04q-4.99 3.2-6.74 8.64c-4.7 14.6-20.72 20.94-33.72 11.48-1.67-1.22-3.9-3.43-2.32-5.59 1-1.37 2.92-1.95 4.31-.77q10.83 9.14 21.16.73Z"
    />
    <path
      fill="#9121a6"
      d="M130.02 70.02c-.08-12.73-5.1-24.77-13.96-33.71q-1.74-1.75-.68-3.97.2-.42.55-.68 2.46-1.81 4.58.39 15.28 15.88 15.66 37.8.04 2.87-2.77 3.48-.38.09-.76 0-2.61-.63-2.62-3.31Z"
    />
    <path
      fill="#cfb2de"
      d="M96.34 85.55c9.86-10.1 8.36-25.14-3.2-33.04q-3.92-2.68-9.06-3.22c-10.36-1.1-19.21 5.01-22.47 14.82-.69 2.07-.78 4.1-1.04 6.22q-.22 1.83-1.86 2.68-.67.35-1.35.26-2.86-.36-2.8-3.25c.35-18.82 18.7-31.89 36.64-25.32 18.75 6.88 23.57 30.43 10.01 44.58q-1.89 1.97-4.18.49-1.05-.68-1.32-2.15a2.36 2.35 62 0 1 .63-2.07Z"
    />
    <rect
      fill="#9121a6"
      x="-3.05"
      y="-6.08"
      transform="rotate(-44.8 126.11 -64.082)"
      width="6.1"
      height="12.16"
      rx="2.93"
    />
    <rect
      fill="#9121a6"
      x="-3.07"
      y="-6.09"
      transform="rotate(-45 132.074 -48.107)"
      width="6.14"
      height="12.18"
      rx="2.95"
    />
    <rect
      fill="#9121a6"
      x="-11.6"
      y="-3.06"
      transform="rotate(44.9 -75.082 121.21)"
      width="23.2"
      height="6.12"
      rx="2.99"
    />
    <rect
      fill="#9121a6"
      x="-3.07"
      y="-6.08"
      transform="rotate(-44.9 145.17 -17.354)"
      width="6.14"
      height="12.16"
      rx="2.95"
    />
    <rect
      fill="#9121a6"
      x="-6.04"
      y="-3.07"
      transform="rotate(44.2 -108.332 109.31)"
      width="12.08"
      height="6.14"
      rx="2.95"
    />
  </svg>
);
